import React, { useEffect, useRef, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react'
import { Bounce, ToastContainer, toast } from 'react-toastify'; // Import react-toastify components
import 'react-toastify/dist/ReactToastify.css'; // Import default CSS for toastify
import "../styles/mylove.css";

export default function App() {
    const [emblaRef] = useEmblaCarousel()
    const images = [ // Replace with actual image names
        '1.png',
        '2.png',
        '3.png',
        '4.png',
        '5.png'
    ];

    const [errorSentences, setErrorSentences] = useState([
        "It's not that one, baby!",
        "Babyyy, you got this :>",
        "Try again, my princesss :>",
        "I love you babyyy"
    ]);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showContentModal, setShowContentModal] = useState(false);
    const [password, setPassword] = useState('');

    const handlePasswordSubmit = () => {
        const lowercasePassword = password.toLowerCase();
        if (lowercasePassword === '2december2023') {
            setShowPasswordModal(false);
            setShowContentModal(true);
        } else {
            const randomIndex = Math.floor(Math.random() * errorSentences.length);

            toast.error(errorSentences[randomIndex]);
        }
    };


    useEffect(() => {
        // Add a class to the body for the Zarucha background
        document.body.classList.add("zarucha-background");

        // Cleanup: Remove the class when the component is unmounted
        return () => {
            document.body.classList.remove("zarucha-background");
        };
    }, []);

    return (
        <>
            <div className="headline-container">
                <h1 className="headline">To the most pretty and beautiful girl in the world
                </h1>
                <h1 className="headline">My sweet and precious Zarucha</h1>
            </div>


            <div className="embla" ref={emblaRef}>
                <div className="embla__container">
                    {images.map((image, index) => (
                        <div className="embla__slide" key={index}>
                            <img
                                src={require(`../img/letter/${image}`)}
                                alt={`Slide ${index + 1}`}
                                className="embla__image"
                            />
                        </div>
                    ))}
                </div>
            </div>


            <div className="button-container">
                <button
                    className="carousel-button"
                    onClick={() => setShowPasswordModal(true)}
                >
                    Click Me!
                </button>
            </div>


            {showPasswordModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Enter the Secret Password</h2>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="password-input"
                            placeholder="Enter password here..."
                        />
                        <button onClick={handlePasswordSubmit}>Submit</button>
                        <button onClick={() => setShowPasswordModal(false)}>Cancel</button>
                    </div>
                </div>
            )}


            {showContentModal && (
                <div className="modal-overlay">
                    <div className="modal-container">
                        <div className="letter-content">
                            <h1>To my beautiful Princess!</h1>

                            <div className="letter-body">
                                <p className="opening">My sweet Zarina,</p>
                                <p>One year ago, we were probably kissing right now! This year we are not physically together but, with all my heart, I'm sure one day we will.</p>
                                <p>Even if you're not here and I'm not there, I hold you very, veryyy deep in my heart.</p>
                                <p className="highlight">I want you to know, and never doubt, from the bottom of my soul, I LOVE YOU! I love you, love you, love you!</p>
                                <p>Life has ups and downs, but you are always there, my princess. And I'm always here for you my love! No matter what, I'll always be here for you.</p>
                                <p className="closing">I miss you tremendously, I miss your smell, I miss your skin, I miss your eyes, I miss your touch, I miss you💞💞💞 I live for the day we are together 😊</p>
                                <div className="ps-section">
                                    <p>P.S. A little something to help you (and maybe us) watch movies </p>
                                    <div className="gift-code">NAA4UBTF36FPR95D</div>
                                </div>
                            </div>
                            <div className="letter-button">
                                <button onClick={() => setShowContentModal(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <ToastContainer
                position="bottom-center" // Position the toast at the bottom center
                autoClose={3000} // Close the toast automatically after 3 seconds
                hideProgressBar={true} // Hide the progress bar
                closeOnClick={true} // Close toast on click
                pauseOnHover={false} // Do not pause on hover
                theme="colored" // Use colored theme
                transition={Bounce}
            />


        </>
    );
}
