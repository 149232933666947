import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import BtnDarkMode from '../btnDarkMode/BtnDarkMode';
import logo from '../../img/icons/logo2.png';
import './style.css';

const Navbar = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const activeLink = 'nav-list__link nav-list__link--active';
	const normalLink = 'nav-list__link';

	// Toggle menu function
	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<nav className="nav">
			<div className="container">
				<div className="nav-row">
					<NavLink to="/" className="logo">
						<img src={logo} className="mr-2" width="50px" height="50px" alt="Logo" />
						<strong>Welcome</strong>, kind stranger!
					</NavLink>

					<BtnDarkMode />

					{/* Hamburger menu icon for mobile */}
					<div className="menu-icon" onClick={toggleMenu}>
						<span className={isMenuOpen ? 'menu-icon__bar open' : 'menu-icon__bar'}></span>
						<span className={isMenuOpen ? 'menu-icon__bar open' : 'menu-icon__bar'}></span>
						<span className={isMenuOpen ? 'menu-icon__bar open' : 'menu-icon__bar'}></span>
					</div>

					{/* Menu items */}
					<ul className={`nav-list ${isMenuOpen ? 'nav-list--open' : ''}`}>
						<li className="nav-list__item">
							<NavLink
								to="/"
								className={({ isActive }) =>
									isActive ? activeLink : normalLink
								}
								onClick={toggleMenu} // Close menu on link click
							>
								Home
							</NavLink>
						</li>

						<li className="nav-list__item">
							<NavLink
								to="/projects"
								className={({ isActive }) =>
									isActive ? activeLink : normalLink
								}
								onClick={toggleMenu} // Close menu on link click
							>
								Projects
							</NavLink>
						</li>

						<li className="nav-list__item">
							<NavLink
								to="/contacts"
								className={({ isActive }) =>
									isActive ? activeLink : normalLink
								}
								onClick={toggleMenu} // Close menu on link click
							>
								Contacts
							</NavLink>
						</li>

						<li className="nav-list__item">
							<NavLink
								to="/travels"
								className={({ isActive }) =>
									isActive ? activeLink : normalLink
								}
								onClick={toggleMenu} // Close menu on link click
							>
								Travels
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
