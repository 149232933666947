import "./styles/main.css";


import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import Contacts from "./pages/Contacts";
import Travels from "./pages/Travels";

import ScrollToTop from "./utils/scrollToTop";

import ReactMarkdown from 'react-markdown';
import MyLove from "./pages/Zarucha";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <ConditionalLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/project/:id" element={<Project />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/travels" element={<Travels />} />
            <Route path="/fishgame" element={<RedirectToGame />} />
            <Route path="/mylove" element={<MyLove />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </ConditionalLayout>
      </Router>
    </div>
  );
}

const RedirectToGame = () => {
  window.location.href = "/project/fish.html"; // Path to your WebGL HTML file
  return null;
};

const ConditionalLayout = ({ children }) => {
  const location = useLocation();
  const hideNavbarFooterPaths = ["/mylove"];

  const shouldHideLayout = hideNavbarFooterPaths.includes(location.pathname);

  return (
    <>
      {!shouldHideLayout && <Navbar />}
      {children}
      {!shouldHideLayout && <Footer />}
    </>
  );
};

export default App;
